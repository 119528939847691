@use "../../variable.scss" as *;
@use "../../mixin.scss" as *;

.what-people-says {
  padding: 50px 0 70px;
  .container {
    @include media575() {
      padding: 0 0 0 20px;
    }
  }
  &__review {
    &-card {
      background-color: #fafafa;
      padding: 40px;
      border-radius: 40px;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
      min-height: 380px;
      color: $dark-color;
      margin-top: 50px;

      @include media991() {
        padding: 20px;
        border-radius: 10px;
        min-height: 304px;
        margin-top: 24px;
      }
      .small-flex {
        display: flex;
        margin: 10px 0;
      }
      h3 {
        font-size: 20px;
        line-height: 140%;
        text-transform: capitalize;
        font-family: $primary-font-700;
        @include media991() {
          font-size: 16px;
        }
      }
      .quoted-text {
        color: $sub-dark-color;
        line-height: 140%;
        @include media991() {
          font-size: 14px;
        }
        @include media575() {
          padding: 0 22px 0 0;
        }
      }
      .reviewer-details {
        line-height: 20px;

        .review-name-flex {
          display: flex;
          align-items: center;
          color: $black-color;
          .name {
            font-size: 14px;
            font-weight: 700;
          }
          .verified {
            font-size: 12px;
            margin-left: 5px;
          }
        }
        .border-vr {
          height: 11px;
          width: 1.5px;
          background-color: $dark-color;
          margin: 3px 10px 0 10px;
        }
      }
    }
  }
  .slick-initialized .slick-slide {
    padding: 0 27px 0 0;
    @include media575() {
      padding: 0 10px;
    }
  }

  .slick-dots {
    display: none;
  }

  .slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -20px;
    margin-bottom: -30px;
  }

  .slick-dots.slick-thumb li {
    width: 8px;
    height: 8px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: #f2f1f1;
  }

  .slick-dots.slick-thumb li.slick-active {
    background-color: #188bf6;
    width: 24px;
  }
}
