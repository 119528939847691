@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

header {
  background: $white-color;
  padding: 23px 0;
  @include media575() {
    padding: 7.5px 0;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media575() {
      padding: 0px 0;
    }
  }
  a {
    text-decoration: none;
  }
  .logo {
    display: flex;
    align-items: center;

    figure {
      max-width: 89px;
      max-height: 84px;
      @include media575() {
        max-width: 35.85px;
        max-height: 33.84px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .header__details {
    font-family: $secondary-font;
    h2 {
      color: $header-font-color;
      letter-spacing: 0.4px;
      @include media575() {
        font-size: 12px;
      }
    }
    span {
      display: block;
      line-height: 22px;
      @include media575() {
        line-height: normal;
      }
    }
    p {
      font-size: 10px;
      color: #2e3a58;
      margin-top: 6px;
      @include media575() {
        font-size: 6px;
        margin-top: 0;
      }
    }
  }
  .business-timing {
    position: absolute;
    right: 0;
    background-color: $primary-color;
    padding: 18px 128px 18px 36px;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: center;
    @include media767() {
      padding-right: 30px;
    }
    @include media575() {
      padding: 4px 10px;
      font-size: 14px;
    }
    @include media475() {
      font-size: 10px;
    }
    figure {
      max-width: 40px;
      max-height: 40px;
      margin-right: 15px;
      @include media475() {
        max-width: 25px;
        max-height: 25px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
